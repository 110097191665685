* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
}

html {
  height: 100%;
  background-repeat: no-repeat;
  background: #F5F2E8;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}
  
.header-container {
    padding-top: 20px;
}
  
.header {
    margin: 0;
    font-size: 100px;
    letter-spacing: 1rem;
    font-family: 'WSfont';
    font-weight: 400;
    color:black;
}

.crypto {
  border-top: .25rem black solid;
  border-bottom: .25rem black solid;
  margin-bottom: 2rem;
}

.arrow {
  width: 30px;
  height: auto;
  vertical-align: middle;
  margin-right: 1.75rem;
  margin-left: 0.25rem;
}

.sold-out {
  margin: 0;
  font-size: 128px;
  letter-spacing: .3rem;
  font-family:'Times New Roman', Times, serif;
  font-weight: 400;
  color:white;
}

.example {
  max-width: 40rem;
  height: auto;
  display: block;
  margin-left: 5rem;
}

.example-content {
  border: 3px solid black;
}

img {
  max-width: 100%;
  max-height: auto;
}

.front-page {
  display: flex;
  flex-direction: row;
}

.front-page-content {
  height: auto;
  margin-top: 2rem;
  margin-left: 7rem;
  margin-right: 8rem;
  max-width: 50%;
}

.subline {
  margin-top: 1rem;
  font-style: italic;
}
 
.button:hover {
    cursor: pointer;
}
  
.button {
    background: -webkit-linear-gradient(left, #02020A, #02020aa4);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
    font-family: 'Times New Roman', Times, serif;
}
  
.cta-button {
    height: 6rem;
    border: 0;
    width: auto;
    padding-left:2.5rem;
    padding-right:2.5rem;
    padding-top:0px;
    margin-top:1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 2px;
    color: white;
}
  
.connect-wallet-button {
    background: -webkit-linear-gradient(left, #02020A, #02020aa4);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
}
  
.button-container {
    padding-top: 40px;
    margin-bottom: 40px;
}
  
.count-button {
    height: 2.5rem;
    border: 0;
    width: auto;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top:0px;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    background: -webkit-linear-gradient(left, #02020A, #02020aa4);
    background-size: 200% 200%;
}
  
.count {
    color: black;
    font-size: 64px;
    font-weight: 400;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'Times New Roman', Times, serif;
}

.description {
  color: black;
  font-size:xx-large;
  margin-top: 4rem;
}
  
  /* KeyFrames */
@-webkit-keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}
@-moz-keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}
@keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}
  

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap');

@font-face {
    font-family: "WSfont";
    src: local("WSfont"), url("./assets/fonts/WSfont.ttf") format("truetype");
}

@media screen and (max-width: 1000px) {
    body {
        font-size: 18px;
    }

    .accordion {
        width: 90%;
    }

    .header {
      font-size: 70px;
      letter-spacing: .5rem;
    }

    img {
      width: 25rem;
      height: auto;
    }

    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
  }
}